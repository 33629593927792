import React from 'react'

const Footer = ({ t }) => {
    return (
        <footer className="md:flex md:items-center lg:absolute inset-x-0 bottom-0 p-3">
            <span className="text-sm sm:text-center">© 2023
                <a href="https://netcalculator.app/"
                    className="hover:underline"> Netcalculator
                </a> - All Rights Reserved.
            </span>
            <div className='lg:pl-80'>
                <ul className="flex mt-3 text-sm sm:mt-0 justify-center">
                    <li>
                        <a href="/imprint" className="mr-4 hover:underline md:mr-6">
                            {t('footer.imprint')}
                        </a>
                    </li>
                    <li>
                        <a href="/privatePolicy"
                            className="mr-4 hover:underline md:mr-6">
                            {t('footer.privacy')}
                        </a>
                    </li>
                    <li>
                        <a href="mailto:support@netcalculator.app"
                            className="hover:underline">{t('footer.contact')}</a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}
export default Footer