import React from 'react';
import { Route, Routes } from 'react-router-dom'

import Footer from './components/Footer';
import Nav from './components/Nav';
import Imprint from './components/Imprint'
import NoPage from './components/NoPage'
import Home from './components/Home'
import PrivatePolicy from './components/PrivatePolicy'
import { useTranslation } from 'react-i18next';

function App() {
    const { t } = useTranslation();

    return (
        <>
            <div
                className="flex flex-col h-screen
                dark:bg-black bg-transparent relative
                text-textCol-light dark:text-textCol-dark
                text-sm md:text-4xl font-light justify-between">
                <Nav />
                <Routes>
                    <Route path='/' element={<Home t={t} />} />
                    <Route path='/imprint' element={<Imprint t={t} />} />
                    <Route path='/privatePolicy'
                        element={<PrivatePolicy t={t} />} />
                    <Route path='*' element={<NoPage t={t} />} />
                    <Route />
                </Routes>
                <Footer t={t} />
            </div>
        </>
    );
}
export default App;