import React from 'react'
import { Typewriter } from 'react-simple-typewriter'

const NoPage = ({ t }) => {
  return (
    <>
      <div className="items-center self-center m-auto left-0 right-0">
        <Typewriter
          words={['404', 'Page not found', 'Die Seite wurde nicht gefunden']}
          loop={5}
          cursor
          cursorStyle='_'
          typeSpeed={70}
          deleteSpeed={50}
          delaySpeed={1000}
        />
      </div>

      <div class="items-center self-center m-auto left-0 right-0">
        <a class="bg-black dark:bg-backgroundCol-light dark:text-textCol-light
        text-textCol-dark py-2 px-4 text-lg border rounded"
          href='/'>Startseite</a>

        <a class="bg-black dark:bg-backgroundCol-light dark:text-textCol-light
        text-textCol-dark py-2 px-4 text-lg border rounded"
          href="mailto:support@netcalculator.app">Kontakt</a>
      </div>
    </>
  )
}

export default NoPage