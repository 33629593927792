import React from 'react'
import { useState } from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import useDarkSide from '../helper/useDarkSide';
import Icon from '@mdi/react';
import { mdiIpNetworkOutline } from '@mdi/js';

const Nav = () => {
    const [colorTheme, setTheme] = useDarkSide();
    const [darkSide, setDarkSide] = useState(
        colorTheme === "light" ? true : false
    );

    const toggleDarkMode = (checked) => {
        setTheme(colorTheme)
        setDarkSide(checked);
    };

    return (
        <>
            <header className="lg:absolute">
                <nav className="p-4 md:p-6">
                    <div className="container flex flex-wrap justify-between
                    mx-auto">
                        <a href="https://netcalculator.app/"
                            className="flex items-start">
                            <Icon path={mdiIpNetworkOutline}
                                size={1}
                                horizontal
                                vertical
                                rotate={180}
                                color={
                                    colorTheme === "light" ? "white" : "black"
                                }
                                className="mr-3 h-6 sm:h-9"
                                alt="Netcalculator App Logo"
                            />
                            <span className="self-center text-xl font-semibold
                            whitespace-nowrap">Netcalculator</span>
                        </a>
                        <div className="flex lg:pl-11 items-end justify-end">
                            <DarkModeSwitch
                                checked={darkSide}
                                onChange={toggleDarkMode}
                                size={25}
                            />
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}
export default Nav
