import React from 'react'

const Imprint = ({ t }) => {
  return (
    <>
      <div className="lg:flex items-center p-10 lg:p-15">
        <h1 className="text-2xl pb-5 sm:text-6xl lg:p-40 font-extrabold">
          {t('imprint.header')}
        </h1>
        <span className="flex flex-col space-y-4 text-lg">
          <p>{t('imprint.text')}</p>

          <span
            className="grid grid-cols-0 gap-2 pt-2 pb-2 lg:grid-cols-2"
          >
            <p className="col-start-1 font-bold">
              {t('imprint.email')}
            </p>
            <a
              className="col-start-1 lg:col-start-2 underline underline-offset-8"
              href='mailto:support@netcalculator.app'>
              support@netcalculator.app
            </a>
            <p className="col-start-1 font-bold">
              {t('imprint.postal')}
            </p>
            <span className="col-start-1 lg:col-start-2">
              <p>{t('imprint.name')}</p>
              <p>{t('imprint.addresse')}</p>
              <p>{t('imprint.location')}</p>
              <p>{t('imprint.country')}</p>
            </span>
            <p className="col-start-1 font-bold lg:col-start-1">
              {t('imprint.update')}
            </p>
            <p className="col-start-1 lg:col-start-2">
              {t('imprint.updateDate')}
            </p>
            <p className="col-start-1 lg:col-start-2">
              {t('imprint.disclaimerText')}
            </p>
          </span>
        </span>
      </div>
    </>
  )
}
export default Imprint