import React from 'react'

const Home = ({ t }) => {
  return (
    <>
      <img className="bg-no-repeat bg-center bg-cover h-full invisible lg:visible"
        src="/iPhoneMockup.png" alt="iPhone Mockup" />
      <div className="absolute top-28">
        <div className="pt-16 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
          <div
            className="mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
            <div className="sm:max-w-lg">
              <h1 className="font text-4xl font-bold tracking-tight sm:text-6xl">
                Netcalculator
              </h1>
              <p className="mt-4 text-xl text-gray-500">
                {t('home.text')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Home